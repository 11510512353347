@import url('https://fonts.googleapis.com/css?family=Titillium+Web');

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;

  font-family: 'Titillium Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}